
  import { defineComponent, onMounted, ref, computed } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import jsPDF from 'jspdf';
  import autoTable from 'jspdf-autotable';
  import { getUser } from '@/core/services/AuthService';
  import LookupNameIds from '@/store/enums/settingsEnums';
  import { injectSearch } from '@/core/plugins/search-service';
  import SearchField from '@/components/common/SearchField.vue';
  import { loadImage } from '../../../utils/loadImage';
  import { format12 } from '@/utils/dateFormat';
  import moment from 'moment/moment';

  export default defineComponent({
    name: 'customer-attendance-report',
    components: {
      ErrorMessage,
      Field,
      Form,
      SearchField,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();
      const selectedItem = ref<number>(0);
      const loadingPage = ref(true);
      const searchService = injectSearch();
      const reportData = ref();
      const selectedCustomer = ref<number>(0);
      const selectedTrainer = ref<number>(0);
      const selectedHorse = ref<number>(0);
      const selectedArena = ref<number>(0);
      const selectedAttendance = ref<number>(0);
      const selecteDuration = ref<number>(0);
      const selecteType = ref<number>(0);
      const selectedDay = ref<number>(0);
      const selectedGroomer = ref<number>(0);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const startDate = ref(moment().startOf('day')._d);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const endDate = ref(moment().startOf('day')._d);
      const startTime = ref();
      const endTime = ref();
      store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(async () => {
        setCurrentPageBreadcrumbs(
          translate('Daily Schedule Report For Trainers'),
          [translate('reports')]
        );
        store.dispatch(Actions.GET_ALL_CUSTOMERS);
        store.dispatch(Actions.GET_ALL_TRAINERS, {});
        store.dispatch(Actions.GET_ALL_ARENAS);
        store.dispatch(Actions.GET_ALL_HORSES_OPERATIONS);
        store.dispatch(Actions.GET_ALL_GROOMERS_OPERATIONS);
        const getSaveData = searchService.getSearchValue('lessonReport');
        const dataSaved =
          Object.keys(getSaveData).length > 0
            ? JSON.parse(searchService.getSearchValue('lessonReport'))
            : {};
        if (Object.keys(getSaveData).length > 0) {
          if (dataSaved.startDate) startDate.value = dataSaved.startDate;
          if (dataSaved.endDate) endDate.value = dataSaved.endDate;
          if (dataSaved.startTime) startTime.value = dataSaved.startTime;
          if (dataSaved.endTime) endTime.value = dataSaved.endTime;
          if (dataSaved.groomerId) selectedGroomer.value = dataSaved.groomerId;
          if (dataSaved.dayId) selectedDay.value = dataSaved.dayId;
          if (dataSaved.typeId) selecteType.value = dataSaved.typeId;
          if (dataSaved.durationId)
            selecteDuration.value = dataSaved.durationId;
          if (dataSaved.attendanceId)
            selectedAttendance.value = dataSaved.attendanceId;
          if (dataSaved.arenaId) selectedArena.value = dataSaved.arenaId;
          if (dataSaved.horseId) selectedHorse.value = dataSaved.horseId;
          if (dataSaved.trainerId) selectedTrainer.value = dataSaved.trainerId;
          if (dataSaved.customerId)
            selectedCustomer.value = dataSaved.customerId;
        }
        loadingPage.value = false;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
      });
      const clearSearch = () => {
        searchService.removeSearchValue('lessonReport');
        selectedCustomer.value = 0;
        selectedTrainer.value = 0;
        selectedHorse.value = 0;
        selectedArena.value = 0;
        selectedAttendance.value = 0;
        selecteDuration.value = 0;
        selecteType.value = 0;
        selectedDay.value = 0;
        selectedGroomer.value = 0;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        startDate.value = moment().startOf('day')._d;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        endDate.value = moment().startOf('day')._d;
        startTime.value = null;
        endTime.value = null;
      };

      const print = async (values) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-extra');

        values.startDate = String(values.startDate);
        values.endDate = String(values.endDate);

        searchService.setSearchValue('lessonReport', values);
        const columnStyles = {};

        columnStyles[10] = {
          halign: 'left',
          cellWidth: 50,
          overflow: 'linebreak',
        };

        const user = getUser();
        const userName = user ? user['name'] : '';

        await store.dispatch(Actions.GET_LESSONS, values);

        reportData.value = store.getters.getLessons;

        const doc = new jsPDF({
          format: 'a4',
          orientation: 'landscape',
        });
        const fontSize = 10;

        let textX =
          (doc.internal.pageSize.getWidth() -
            doc.getTextWidth('Kuwait Riding Center')) /
          2;

        let pageCount = 0;
        reportData.value.data.forEach(function (element, index) {
          const resTabelBody: any[] = [];
          if (index > 0) {
            doc.addPage();
          }

          doc.setFontSize(8);

          doc.text('trainer : ' + element['trainerName'], 15, 50);

          doc.text('Date : ' + element['date'], 230, 50);

          element.lessons.forEach((elementb, index) => {
            doc.text('Count : ' + element.lessons.length, 130, 50);
            resTabelBody.push([
              index + 1,
              elementb['customerName'],
              elementb['horseName'],
              format12(elementb['time']['startTime']),
              format12(elementb['time']['endTime']),
              elementb['arenaName'],
              elementb['horseLocation'],
              elementb['lessonType'],
              elementb['groomerName'],
              elementb['attendance'],
              elementb['comment'],
              {
                content: elementb['classLevelName']
                  ? elementb['classLevelName']
                  : '',
                styles: { fillColor: elementb['color'] },
              },
            ]);
          });

          autoTable(doc, {
            head: [
              [
                'Sr',
                'Customer',
                'Horse',
                'Start Time',
                'End Time',
                'Arena',
                'Location / Prev Arena',
                'Lesson Type',
                'Groomer',
                'Status',
                'Comment',
                'Class Level',
              ],
            ],
            body: resTabelBody,
            startY: 60,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            margin: { horizontal: 5, top: 60 },
            horizontalPageBreak: false,
            theme: 'grid',
            headStyles: {
              fillColor: [0, 72, 115],

              fontStyle: 'bold',
              halign: 'left',
              cellWidth: 'auto',
              fontSize: 7.5,
            },
            bodyStyles: {
              fontStyle: 'bold',
              halign: 'left',
              cellWidth: 'auto',
              fontSize: 7.5,
            },
            columnStyles: columnStyles,
            didDrawPage: function () {
              doc.setFontSize(10);
              pageCount++;
              const pageCountWidth = doc.getTextWidth(String(pageCount));

              doc.text(
                'Page ' + pageCount,
                textX - pageCountWidth,
                doc.internal.pageSize.height - 9
              );
              doc.setFontSize(fontSize);
            },
          });
        });
        const logoImage = await loadImage('/media/logos/empty-krc.png');

        Array.from({ length: pageCount }, (x, i) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.addImage(logoImage, 'png', 5, 3, 35, 35);
          doc.setFontSize(14);
          doc.setFont('helvetica', 'bold');
          doc.text('Kuwait Riding Center', textX, 18);
          doc.setFontSize(12);
          doc.text('Daily Schedule Report For Trainers', textX - 2, 25);
          doc.setFontSize(10);
          doc.text('Print DateTime: ' + moment(new Date()).format('LLL'), 5, 8);

          doc.setFontSize(8);

          doc.text(
            'Start Date: ' + moment(values.startDate).format('LL'),
            100,
            55
          );
          doc.text('End Date: ' + moment(values.endDate).format('LL'), 140, 55);

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          doc.setPage(i + 1);
          doc.text(userName, 5, doc.internal.pageSize.height - 9);
          doc.setFontSize(10);
          doc.setFont('helvetica', 'normal');

          doc.text(
            '   of ' + doc.getNumberOfPages(),
            textX + 10,
            doc.internal.pageSize.height - 9
          );
          doc.setFontSize(fontSize);
        });

        // doc.save('table.pdf');
        // doc.autoPrint();
        const data = doc.output('blob');
        const blobUrl = URL.createObjectURL(data);
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-extra');
        window.open(blobUrl, '_blank');

        //printJs({ printable: blobUrl, type: 'pdf' });
      };

      const validationSchema = Yup.object().shape({});
      // const onSubmitCreate = async (values) => {
      //   debugger;
      //   if (submitButton.value) {
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = true;
      //     submitButton.value.setAttribute('data-kt-indicator', 'on');
      //   }
      //   values.duration = Number(values.duration);
      //   values.numberOfRider = Number(values.numberOfRider);

      //   const { data } = await store.dispatch(
      //     Actions.GET_CUSTOMER_ATTENDANCE,
      //     values
      //   );
      //   const [errorName] = Object.keys(store.getters.getErrors);
      //   const error = store.getters.getErrors[errorName];

      //   if (error) {
      //     Swal.fire({
      //       text: translate(error[0]),
      //       icon: 'error',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('tryAgainExcl'),
      //       customClass: {
      //         confirmButton: 'btn fw-bold btn-light-danger',
      //       },
      //     });
      //     //Deactivate indicator
      //     submitButton.value?.removeAttribute('data-kt-indicator');
      //     // eslint-disable-next-line
      //     submitButton.value!.disabled = false;
      //   } else {
      //     Swal.fire({
      //       text: translate('SUCCESSFULLY_CREATED_CLASS_CATEGORY'),
      //       icon: 'success',
      //       buttonsStyling: false,
      //       confirmButtonText: translate('ok'),
      //       customClass: {
      //         confirmButton: 'btn btn-light-primary',
      //       },
      //     }).then(() => {
      //       router.push({ name: 'class-category-listing' });
      //     });
      //   }
      // };

      await store.dispatch(Actions.GET_ALL_SETTINGS_LOOKUPS, [
        LookupNameIds.DAYS,
        LookupNameIds.CLASS_TYPE,
        LookupNameIds.DURATION,
        LookupNameIds.STATUS,
        LookupNameIds.ATTENDEND,
      ]);

      const lookupsList = computed(() => store.getters.allLookupsList);

      const statusList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.ATTENDEND;
      });

      // const categoryList = await store.dispatch(
      //   Actions.GET_ALL_SETTINGS_CLASS_CATEGORY
      // );
      const classType = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.CLASS_TYPE;
      });

      const dayList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DAYS;
      });

      const durationList = lookupsList.value.filter((lookup) => {
        return lookup.lookupNameId === LookupNameIds.DURATION;
      });

      return {
        selectedItem,
        submitButton,
        translate,
        validationSchema,
        goBack,
        can,
        print,
        reportData,
        customers: computed(() => store.getters.allCustomersList),
        trainers: computed(() => store.getters.allTrainersList),
        arenas: computed(() => store.getters.allArenasList),
        horses: computed(() => store.getters.allHorsesList),
        groomers: computed(() => store.getters.allGroomerList),
        statusList,
        classType,
        // categoryList,
        dayList,
        durationList,
        selectedCustomer,
        selectedTrainer,
        selectedArena,
        selectedHorse,
        selecteDuration,
        selectedAttendance,
        selecteType,
        selectedDay,
        selectedGroomer,
        startDate,
        endDate,
        startTime,
        endTime,
        loadingPage,
        clearSearch,
      };
    },
  });
